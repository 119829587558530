import {
  WorkerMessage,
  WorkerType,
} from "../services/workers/worker.service.props";
import { BacktestWorker } from "./backtest.worker";

onmessage = async (message: MessageEvent<WorkerMessage<any>>) => {
  switch (message.data?.type) {
    case WorkerType.RunBacktests:
      const allWorkers = {
        backtestWorker: new BacktestWorker(message.data.user),
      };

      allWorkers.backtestWorker.runBacktests(message.data);
      break;
  }
};

export {};
