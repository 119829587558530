import _ from "lodash";
import axios, { isAxiosError } from "axios";
import { ENV } from "../config/env";
import packageConfig from "../../package.json";
import { WorkerUser } from "../services/workers/worker.service.props";

export class ApiWorker {
  constructor(private user: WorkerUser) {}

  private getHeaders() {
    return {
      Authorization: `Bearer ${this.user.token}`,
      Accept: "application/json",
      "Cache-Control": "no-cache",
      "app-version": packageConfig.version,
    };
  }

  getAxiosInstance(customHeaders?: Record<string, any>) {
    const instance = axios.create({
      baseURL: ENV.apiUrl,
      timeout: 30000,
      headers: Object.assign({}, this.getHeaders(), customHeaders),
    });

    instance.interceptors.request.use((request) => {
      return request;
    });

    instance.interceptors.response.use(
      (response) => {
        // Strip BaseResponse from response here
        if (response.data?.data) {
          _.set(response, "data", response.data.data);
        }

        return response;
      },
      (error) => {
        if (isAxiosError(error)) {
          console.error("API Error: ", error?.response?.data);
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }

  getHistoricalData() {
    console.log("Worker API:");
  }

  /**
   * Checks if given jobs are valid and returns the job body for the one's that
   * are valid by marking them as STARTED
   */
  validateJobs(jobIds: string) {}

  async validateToken(token: string) {
    try {
      const response = await this.getAxiosInstance().get(
        `public/auth/token/validate?token=${token}`
      );

      console.log("Worker API Response: ", response);
    } catch (error) {
      console.log("Error: ", error);
    }
  }
}
