{
  "name": "algocherry-web",
  "version": "0.1.0",
  "private": true,
  "dependencies": {
    "@emotion/react": "^11.11.4",
    "@emotion/styled": "^11.11.5",
    "@mui/icons-material": "^5.15.15",
    "@mui/material": "^5.15.15",
    "@mui/styles": "^5.14.1",
    "@mui/x-date-pickers": "^6.16.2",
    "@react-oauth/google": "^0.12.1",
    "@sentry/cli": "^2.32.1",
    "@sentry/react": "^8.11.0",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^13.4.0",
    "@testing-library/user-event": "^13.5.0",
    "@types/jest": "^27.5.2",
    "@types/node": "^16.18.36",
    "@types/react": "^18.2.14",
    "@types/react-dom": "^18.2.6",
    "axios": "^1.7.2",
    "chart.js": "^4.3.0",
    "chartjs-plugin-zoom": "^2.0.1",
    "dayjs": "^1.11.10",
    "eventemitter3": "^5.0.1",
    "lodash": "^4.17.21",
    "mobx": "^6.9.0",
    "mobx-react": "^7.6.0",
    "moment": "^2.29.4",
    "react": "^18.2.0",
    "react-chartjs-2": "^5.2.0",
    "react-copy-to-clipboard": "^5.1.0",
    "react-dom": "^18.2.0",
    "react-router-dom": "^6.16.0",
    "react-scripts": "5.0.1",
    "react-toastify": "^9.1.3",
    "react-tooltip": "^5.21.5",
    "typescript": "^4.9.5",
    "web-vitals": "^2.1.4"
  },
  "scripts": {
    "worker-setup": "node worker-setup.js",
    "build": "npm run worker-setup && env-cmd -f .env.dev react-scripts build",
    "start": "npm run worker-setup && env-cmd -f .env.dev react-scripts start",
    "serve": "serve -s build",
    "build:stage": "npm run worker-setup .env.staging && env-cmd -f .env.staging react-scripts build",
    "start:stage": "npm run worker-setup .env.staging && yarn build:stage && yarn serve",
    "start:stage-pm2": "pm2 start 'yarn start:stage'",
    "build:prod": "npm run worker-setup .env.production && GENERATE_SOURCEMAP=false env-cmd -f .env.production react-scripts build --no-source-map && yarn sentry:sourcemaps",
    "start:prod": "npm run worker-setup .env.production && yarn build:prod && yarn serve",
    "start:prod-pm2": "pm2 start 'yarn start:prod'",
    "deploy": "yarn build:prod && firebase deploy --only hosting",
    "logs:stage-pm2": "pm2 logs 'yarn start:stage-pm2'",
    "test": "react-scripts test",
    "eject": "react-scripts eject",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org algocherry --project javascript-react build && sentry-cli sourcemaps upload --org algocherry --project javascript-react build"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@babel/plugin-proposal-private-property-in-object": "^7.21.11",
    "@types/eventemitter3": "^2.0.2",
    "@types/lodash": "^4.14.195",
    "@types/react-copy-to-clipboard": "^5.0.4",
    "@types/socket.io-client": "^3.0.0",
    "buffer": "^5.5.0||^6.0.0",
    "env-cmd": "^10.1.0",
    "parcel": "^2.12.0",
    "prettier": "^2.8.8",
    "process": "^0.11.10"
  }
}
