class Env {
  appName = "Algocherry";
  env = process.env.REACT_APP_ENV ?? "";
  appUrl = process.env.REACT_APP_WEBSITE_URL ?? "";
  apiUrl = process.env.REACT_APP_API_URL ?? "";
  aoApiKey = process.env.REACT_APP_ANGEL_ONE_KEY ?? "";
  socketUrl = process.env.REACT_APP_SOCKET_URL ?? "";
  rzpayKey = process.env.REACT_APP_RZPAY_KEY ?? "";
  infoEmail = process.env.REACT_APP_INFO_EMAIL ?? "";
  googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? "";

  trialPlanName = "Trial";

  isTaxable() {
    return false;
  }

  isProd() {
    return this.env === "production";
  }

  isStaging() {
    return this.env === "staging";
  }
}

export const ENV = new Env();
