export enum WorkerType {
  RunBacktests = "RUN_BACKTESTS",
}

export interface WorkerUser {
  id: string;
  token: string;
}

export type WorkerPayload<T extends WorkerType> =
  T extends WorkerType.RunBacktests
    ? {
        jobIds: string[];
      }
    : undefined;

export interface WorkerMessage<T extends WorkerType> {
  type: WorkerType;
  payload: WorkerPayload<T>;
  user: WorkerUser;
}
