import {
  WorkerMessage,
  WorkerType,
  WorkerUser,
} from "../services/workers/worker.service.props";
import { ApiWorker } from "./api.worker";

export class BacktestWorker {
  api: ApiWorker;

  constructor(private user: WorkerUser) {
    this.api = new ApiWorker(user);
  }

  runBacktests(payload: WorkerMessage<WorkerType.RunBacktests>) {
    console.log("Running backtests: ", payload);
    // api.getData();
    this.api.validateToken(this.user.token);
  }
}
